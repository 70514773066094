.data-mapping {
    .ant-tree-treenode {
        padding-block: .5rem !important;
        width: 100%;
    }

    .ant-tree-title {
        .ant-space {
            width: 100%;
            .ant-space-item {
                width: 100%;
            }
        }
    }

    .ant-tree-node-content-wrapper {
        width: 100%;
    }

    .data-mapping-header {

        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 10vh;
    }

    .data-mapping-tree-content {

        background: white;
        height: 65vh;
        overflow: auto;
        padding: 1rem;
    }

    .data-mapping-control {
        align-items: center;
        display: flex;
        height: 10vh;
        justify-content: center;
    }

    .field-row {
        align-items: center;
        column-gap: 1rem;
        display: grid;
        grid-template-columns: 1rem 1fr 50vw;
        width: 100%;

        .input-item {
            width: 100%;
        }

        .select-item {
            width: 100%;
            max-width: 28rem;
        }
    }

    .field-map-row {
        align-items: center;
        column-gap: 1rem;
        display: grid;
        grid-template-columns: 1rem 1fr 30vw 7vw 11vw;
        width: 100%;

        .input-item {
            width: 100%;
        }

        .select-item {
            width: 100%;
            max-width: 28rem;
        }
    }
}

.converter-container {

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .data-mapping-modal {

        margin-top: 1rem;
        width: 640px;
        margin-right: 16px;

        .controls {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 16px;
        }

        .editor {
            border: 1px solid lightgray;
            height: 240px;
            overflow: auto;
        }

        .required-field {
            margin-top: 1rem;
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            border-bottom: 1px solid #eee;
        }
        
        .ant-form-item {
            margin: 0 !important;
        }

        .ant-radio-group {
            width: 100%;
        }
    }

    .ref-container {
        
        display: flex;
        flex-direction: row;
        width: 720px;
        border: 1px solid #eee;
        margin-left: 16px;
        height: 32rem;
        
        .sidebar {
            background-color: #edf1f2;
            border-right: 1px solid #eee;
            height: 100%;
            overflow: auto;
            width: 240px;

            .script-item {
                cursor: pointer;
                padding: 8px;
                color: #015B93;
                border-bottom: 1px solid #becfd4;
            }

            .active {
                background-color: #becfd4;
            }
        }

        .script-container {

            position: relative;
            width: 100%;

            .source-container {

                align-items: center;
                display: flex;
                flex-direction: row;
                padding-inline: 1rem;
                width: calc(100% - 24px);
                height: 2.25rem;
                border-bottom: 1px solid #eee;
                justify-content: flex-start;

                label {
                    width: 7rem;
                    color: gray;
                }
                
                .source {
                    border: 0;
                    height: 100%;
                }
            }

            .reference {
                overflow: auto;
            }
            
            .script {
                width: 100%;
                height: calc(100% - 2.25rem);
                border: 0;
            }

            .copy {
                position: absolute;
                right: 8px;
                bottom: 8px;
            }
        }
    }
}

.preview-container {

    flex-direction: column;
    height: 480px;

    .outpreview-container {

        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        height: calc(100% - 2rem);
        
        .content-container {

            margin-top: 1rem;
            height: calc(100% - 3rem);

            .source-container {

                width: 100%;
                height: 100%;
                flex-direction: column;   
                gap: 16px; 

                .textarea {
                    width: 100%;
                    height: 100%;
                    margin-bottom: 1rem;
                }
            }

            .area-container {
                border: 1px solid lightgray;
                border-radius: 4px;
                width: 100%;
                height: 25rem;
                overflow: auto;
            }
        }
    }

    .preview-button-container {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

/* .preview-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
} */

.save-as-controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 32px;
}